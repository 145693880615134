import React from 'react'
import Layout from "../layouts/mdlayout"

const Contactus = () => {
    return (
        <Layout>
            <h1>Contact Us</h1>
            <p>We'd love to hear from you! Whether you have questions, feedback, or need support, please reach out to us using the information below.</p>

            <div class="contact-info">
                <h4>Email</h4>
                <p>For any questions, please email us at <a href="mailto:contact@dynos.io">contact@dynos.io</a>.</p>
            </div>

            <div class="contact-info">
                <h4>Address</h4>
                <div>
                    <strong>India Office:</strong>
                    <p>Dynos Education India Pvt. Ltd. Akshay Tech Park, Plot 72-73, EPIP Zone, Whitefield, Bengaluru (KA) - 560066</p>
                    <div className="map-container">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3890.9946482958193!2d77.72667857426735!3d12.969812890849728!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae13ea82c7f5b1%3A0x7b565ffbd7f49a7b!2sAkshay%20Tech%20Park!5e0!3m2!1sen!2sin!4v1620104321396!5m2!1sen!2sin" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy"></iframe>
                    </div>
                </div>
                <div>
                    <strong>USA Office:</strong>
                    <p>Dynos Inc, 44679 Endicott Dr., Suite 300 #504, Ashburn, VA - 20147</p>
                    <div className="map-container">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3105.117399604159!2d-77.47132028465484!3d39.05698317955019!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b63892a1f81b3d%3A0x64a282fb2f15af7!2s44679%20Endicott%20Dr%20%23300%2C%20Ashburn%2C%20VA%2020147%2C%20USA!5e0!3m2!1sen!2sin!4v1620104375674!5m2!1sen!2sin" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy"></iframe>
                    </div>
                </div>
            </div>
            <hr />
            <p>Thank you for getting in touch with us! We strive to respond to all inquiries as quickly as possible.</p>
        </Layout>
    )
}
export default (Contactus)